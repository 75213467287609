import React, { useEffect, useState } from 'react';
import { numberToDollarFormat } from '../../utils/utility';
import Station from '../../models/Station';
import TouSeason from '../../models/TouSeason';
import { TouPricing, getTouParsed } from '../../utils/tou';

interface PricingBreakdownProps {
    station: Station;
    todayTouSeason: TouSeason;
}

const PricingBreakdown: React.FC<PricingBreakdownProps> = ({
    station,
    todayTouSeason,
}) => {
    const pricing = station?.pricing || station?.inheritedPricingFrom;
    const startDelay = pricing && pricing.startDelay ? pricing.startDelay : 0;
    const penaltyRate =
        pricing && pricing.penaltyRate ? pricing.penaltyRate : 0;
    const penaltyDelay =
        pricing && pricing.penaltyDelay ? pricing.penaltyDelay : 0;
    const [touData, setTouData] = useState<TouPricing[]>([]);

    useEffect(() => {
        if (
            pricing &&
            pricing.policy.toUpperCase() === 'TOU' &&
            pricing.jsonData
        )
            setTouData(getTouParsed(todayTouSeason, pricing.jsonData));
    }, [pricing, todayTouSeason]);

    return (
        <div className="pricing__current-price">
            <div className="pricing__show-full">
                <div className="modal__price-details-container">
                    {startDelay > 0 && penaltyRate > 0 && penaltyDelay > 0 && (
                        <p className="modal__price-details">
                            First {startDelay / 60}hr
                            {startDelay / 60 > 1 && 's'} free,{' '}
                            {penaltyDelay / 60}hr{penaltyDelay / 60 > 1 && 's'}{' '}
                            max
                        </p>
                    )}
                    {startDelay > 0 &&
                        (penaltyRate === 0 || penaltyDelay === 0) && (
                            <p className="modal__price-details">
                                First {startDelay / 60}hr
                                {startDelay / 60 > 1 && 's'} free
                            </p>
                        )}
                    {startDelay === 0 &&
                        penaltyRate > 0 &&
                        penaltyDelay > 0 && (
                            <p className="modal__price-details">
                                {Math.floor((penaltyDelay / 60) * 100) / 100} hr
                                {Math.floor((penaltyDelay / 60) * 100) / 100 >
                                    1 && 's'}{' '}
                                max
                            </p>
                        )}
                    {penaltyRate > 0 && penaltyDelay > 0 && (
                        <p className="modal__price-details">
                            {numberToDollarFormat(penaltyRate)} penalty fee per
                            additional hour
                        </p>
                    )}
                </div>
                {pricing && pricing.policy === 'TOU' && (
                    <div>
                        <p className="pricing__price-details-title">
                            TIME-OF-USE RATES
                        </p>
                        <div className="modal__price-tou-container">
                            <div className="modal__tou-times">
                                {touData.map((item) => {
                                    return (
                                        <p
                                            key={item.times}
                                            className="modal__tou-detail"
                                        >
                                            {item.times}
                                        </p>
                                    );
                                })}
                            </div>
                            <div className="modal__tou-prices">
                                {touData.map((item) => {
                                    return (
                                        <p
                                            key={item.times}
                                            className="modal__tou-detail"
                                        >
                                            {item.price}
                                            /kWh
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PricingBreakdown;
