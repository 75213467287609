import { colors } from '../global/variables';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';
import { motion } from 'framer-motion';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { triggerReviewPrompt } from '../utils/webview/messages';
import { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

interface ModalProps {
    onClose: () => void;
    onCloseRedirect: () => void;
}
const recordAppReviewQuery = loader('../gql/recordAppReview.graphql');
const recordAppAnswerQuery = loader('../gql/recordAppAnswer.graphql');

const ReviewPrompt: React.FC<ModalProps> = ({ onClose, onCloseRedirect }) => {
    const { data } = useQuery(recordAppReviewQuery);
    const [answerAppReview, { data: answerAppReviewData }] = useLazyQuery(
        recordAppAnswerQuery,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const triggerPrompt = async () => {
        triggerReviewPrompt();
        await answerAppReview({
            variables: { response: 'yes' },
        });
        onClose();
    };

    const closeAndRedirect = async () => {
        await answerAppReview({
            variables: { response: 'no' },
        });
        onCloseRedirect();
    };

    return (
        <>
            <div className="modal" id="error_modal">
                <div
                    className="modal__background"
                    onClick={onClose}
                    id="error_modal_background"
                ></div>
                <motion.div
                    initial={{ top: '100%' }}
                    animate={{ top: 0 }}
                    className="modal__content-error"
                    id="error_modal_close_content"
                >
                    <div
                        className="modal__close-container"
                        id="error_modal_close_container"
                    >
                        <div
                            className="modal__close"
                            onClick={onClose}
                            id="error_modal_close"
                        >
                            <CloseIcon
                                fill={colors.inactiveWhite}
                                id="error_modal_close_icon"
                            />
                        </div>
                    </div>
                    <p
                        className="modal__title"
                        style={{ textAlign: 'center', marginBottom: 30 }}
                        id="review_modal_title"
                    >
                        Are you enjoying the Chargie app?
                    </p>
                    <PrimaryButton
                        title="Yes"
                        onPress={() => triggerPrompt()}
                        scaleFont={false}
                    />
                    <SecondaryButton
                        title="No"
                        border={false}
                        onPress={() => closeAndRedirect()}
                    />
                </motion.div>
                <div className="modal__content--inset" />
            </div>
        </>
    );
};

export default ReviewPrompt;
