import { Phase, PricingJsonData } from '../models/Pricing';
import TouSeason from '../models/TouSeason';
import { numberToDollarFormat } from './utility';

export type TouPricing = {
    times: string;
    price: string;
    active: boolean;
};

type TouTimes = {
    start: number;
    end: number;
    key: string;
    price: number;
};

export const getTouParsed = (phases: TouSeason, pricing: PricingJsonData) => {
    const seasonPolicy = getSeasonPolicy(phases.name, pricing);
    const times: TouTimes[] = [];
    if (seasonPolicy.length > 0)
        phases.phases.map((phase) => {
            const timeElement: TouTimes = {
                start: getCurrentTimeFromUtcString(phase.startTime),
                end: getCurrentTimeFromUtcString(phase.endTime),
                key: phase.name,
                price: seasonPolicy.filter((p) => p.name === phase.name)[0]
                    .rate,
            };
            times.push(timeElement);
            return timeElement;
        });
    let sortedArr = [...times].sort((a, b) => a.start - b.start);
    if (sortedArr.length > 0) {
        const lastElement = sortedArr.slice(-1)[0];
        if (lastElement.end === sortedArr[0].start && lastElement.end !== 0) {
            lastElement.end = 0;
            const timeElement: TouTimes = {
                start: 0,
                end: sortedArr[0].start,
                key: lastElement.key,
                price: lastElement.price,
            };
            sortedArr = [timeElement, ...sortedArr];
        }
    }
    return setTouPricing(sortedArr);
};

const getSeasonPolicy = (season: string, pricing: PricingJsonData) => {
    let phases: Phase[] = [];
    if (pricing && pricing.policy)
        pricing.policy.map((policyItem) => {
            if (policyItem.name === season) {
                phases = policyItem.phases;
                return policyItem.phases;
            }
            return phases;
        });
    return phases;
};

const getCurrentTimeFromUtcString = (utcString: string) => {
    const today = new Date();
    const dateString = `${today.getFullYear()}-01-01`;
    const utcDate = `${dateString}T${utcString}Z`;
    const dateItem = new Date(utcDate);
    const finalDateString = dateItem.getHours();
    return finalDateString;
};

const setTouPricing = (touTimes: TouTimes[]) => {
    let touPricing: TouPricing[] = [];
    const now = new Date();
    touTimes.map((item) => {
        const phaseDetail: TouPricing = {
            times: setTime(item.start) + ' - ' + setTime(item.end),
            price: numberToDollarFormat(item.price),
            active:
                setTime(item.start) === setTime(item.end)
                    ? true
                    : (item.start <= now.getHours() &&
                          item.end > now.getHours()) ||
                      (item.start <= now.getHours() && item.end === 0),
        };
        touPricing.push(phaseDetail);
        return phaseDetail;
    });
    return touPricing;
};

const setTime = (time: number) => {
    if (time === 0) return '12am';
    return time > 12 ? time - 12 + 'pm' : time + 'am';
};
