import React from 'react';

interface ButtonProps {
    title: string;
    onPress: () => void;
    disabled?: boolean;
    loading?: boolean;
    id?: string;
    scaleFont?: boolean;
}

const PrimaryButton: React.FC<ButtonProps> = ({
    title,
    onPress,
    disabled,
    loading,
    id,
    scaleFont,
    ...props
}) => {
    return (
        <button
            className={disabled ? 'button--disabled' : 'button'}
            onClick={onPress}
            {...props}
            disabled={disabled}
            id={id}
            style={{ fontSize: scaleFont === false ? 16 : 'default' }}
        >
            {loading === true && (
                <div className="loader" style={{ position: 'absolute' }} />
            )}{' '}
            {title}
        </button>
    );
};

export default PrimaryButton;
